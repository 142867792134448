<template lang="pug">
  v-container
    v-row
      v-col
        .details.mb-10
          v-layout(
            align-center
          )
            h1.text-h4 {{ region.name }}
            v-col
              v-chip(
                :color="region.status === 'ACTIVE' ? 'primary' : 'grey lighten-1'"
                small
              ) {{ region.status }}
              .actions.ml-auto
                v-tooltip(top)
                  template(#activator="{ on, attrs}")
                    v-btn.px-0.mr-2.my-1(
                      v-on="on"
                      min-width="40px"
                      @click="deleteRegion"
                      color="error"
                    )
                      v-icon mdi-delete
                  span Delete Region
                v-btn.ml-auto(
                  :to="{ name: 'edit-region', params: { regionId: region.id }}"
                  color="primary"
                )
                  v-icon(
                    left
                  ) mdi-pencil
                  | Edit Region

        v-card.mb-16(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              h3.grey--text.text--darken-2 Shops
              v-spacer
              v-text-field.flex-shrink-1.flex-grow-0(
                v-model="searchShops"
                label="Search Users"
                single-line
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                rounded
                dense
              )
          ShopsTable(
            :search="searchShops"
            :query="{ region_id: region.id }"
            :regionId="region.id"
          )

        v-card.mb-16(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              h3.grey--text.text--darken-2 Users
              v-spacer
              v-text-field.flex-shrink-1.flex-grow-0(
                v-model="searchUsers"
                label="Search Users"
                single-line
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                rounded
                dense
              )
          UsersTable(
            :query="{ region_id: region.id }"
            :regionId="region.id"
            :search="searchUsers"
          )

</template>

<script>
import { useGet } from 'feathers-vuex'
import UsersTable from '@/components/tables/UsersTable'
import ShopsTable from '@/components/tables/ShopsTable'

export default {
  name: 'Region',
  props: {
    regionId: {
      type: String,
      required: true
    }
  },
  components: {
    UsersTable,
    ShopsTable
  },
  setup (props, context) {
    const { Region } = context.root.$FeathersVuex.api

    // Get the patient record
    const { item: region, hasLoaded } = useGet({
      model: Region,
      id: props.regionId
    })

    return {
      region,
      hasLoaded
    }
  },
  methods: {
    async deleteRegion () {
      try {
        const message = 'Are you sure you want to delete this region?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        if (isYes) {
          await this.region.remove()
          this.$snackSuccess('Region Deleted')
          this.$router.push({ name: 'regions' })
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  },
  data: () => ({
    searchShops: null,
    searchUsers: null
  })
}
</script>
